import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Device2.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/circularhead',
    name: 'Device1',
    component: () => import( '../views/Device1.vue')
  },
  {
    path: '/Device2',
    name: 'Device2',
    component: () => import( '../views/Device2.vue')
  },
  
  {
    path: '/Device3',
    name: 'Device3',
    component: () => import( '../views/Device3.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
