<template>
  <div>
    <div  v-if="radioGroup">
      {{ radioGroup.Name }}
      <div v-for="(rb,rbi) in radioGroup.buttons" :key="radioGroup.Name+rbi" @click="doClick(radioGroup,rbi)" style="display:flex; cursor:pointer;">
        <div :class="'box '+(rbi == radioGroup.selected)" ></div>
        <div  >{{rb.name || rb}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
 
  props: {
   radioGroup:{default:-1},
  
  }, 
  mixins:[],
  components:{},
  data() {
    const self=this
    return {
     buttonState:false,
     buttonColor:"inactive",
     lastButton:null
     
   
    }
  },
  mounted(){
   
   
  
  },
  computed:{
    
  },
  methods:{
    setValue(val){
      let rb
       for(var b in this.radioGroup.buttons){
        rb=this.radioGroup.buttons[b]
        if(rb.name==val.Value){
          this.doClick(this.radioGroup,b)
        }
      }
    },
   doClick(radioGroup,index){
    let btn=radioGroup.buttons[index]
    radioGroup.selected=index
    if(btn.name){
      if(this.lastButton){
        this.$store.dispatch("doButton",{name:this.lastButton.name,osc:this.lastButton.osc,param:0})
        this.lastButton=btn
        setTimeout(this.activateBtn,500)
      }else{
        this.$store.dispatch("doButton",{type:"Radio",panel:this.radioGroup.Name,value:btn.name,name:btn.name,osc:btn.osc,param:btn.osc_param})
        this.lastButton=btn
      }
     
    }
    
   },
   activateBtn(){
    this.$store.dispatch("doButton",{type:"Radio",panel:this.radioGroup.Name,value:this.lastButton.name,name:this.lastButton.name,osc:this.lastButton.osc,param:this.lastButton.osc_param})
      
    
   }
    
  },
  watch:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
