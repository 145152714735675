<template>
  <div>
    <div  v-if="palette">
    {{ palette.Name }}
 
   <div v-if="paletteCont" style="display:flex;">
   <div class="swatch" :style="'background-color:'+paletteCol+'; width:64px;height:64px;'"></div>
   <div style="display:flex; flex-wrap:wrap;">
    <div v-for="(rb,rbi) in paletteCont.colours" :key="palette.Name+rbi" @click="doClick(palette,rbi)" class="swatch" :style="'display:flex; cursor:pointer; background-color:'+swatchColour(rb)+';'">
     
    
     </div>
    </div>
   
    
  </div>
</div>
  </div>
</template>

<script>



export default {
 
  props: {
   palette:{default:0},
  
  }, 
  mixins:[],
  components:{},
  data() {
    const self=this
    return {
     selected:0,
     buttonState:false,
     buttonColor:"inactive",
    }
  },
  mounted(){
    this.selected=this.palette.defaultColour
   
  
  },
  computed:{
    paletteCol(){
     let pal
      if(this.selectedColour){
       return this.swatchColour(this.selectedColour)
      }
      return "#F0F"
    },
    selectedColour(){
     
      if(this.paletteCont){
        return this.paletteCont.colours[this.selected]
      
      }else{
        return null
      }
    },
    paletteCont(){
      return this.$store.getters.getPaletteName("Palette1")
    }
  },
  methods:{
    setValue(val){
      for(var c in this.paletteCont.colours){
        if(this.paletteCont.colours[c].indexOf(","+val.Value)>-1){
          this.doClick(this.palette,c)
        }
      }
    },
   swatchColour(pal){
    return pal.split(",")[0]
   },
   doClick(palette,index){
    let comm
    this.selected=index
    let col=this.selectedColour.split(",")
    comm={type:"Colour",panel:palette.Name,name:palette.Name,osc:palette.osc,param: col[1]+","+col[2]+","+col[3],value: col[1]+","+col[2]+","+col[3]}
    this.$store.dispatch("doButton",comm)
    
   },
   
    
  },
  watch:{
    palette(val){
      
      this.selected=val.defaultColour
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.swatch{
  width:40px;
  height:30px;
  border-radius:5px;
  margin:2px;
}
</style>
