
import Button from '@/components/Button'
import Panel from '@/components/Panel'
import RadioButtons from '@/components/RadioButtons.vue'

export default {
  name: 'Home',
  components: {
    Button,RadioButtons,Panel
  },
  data() {
      return {
        scheduleBtns:{Name:"Light Mode",selected:0,buttons:["Off","Schedule","On"]}, 
        pageWidth:600,
        now:null,
       
      }
  },
  mounted(){
   this.setDevice()
   this.$store.dispatch("getSettings")
   this.setPageWidth()
   window.onresize=this.setPageWidth
   this.getTime()
   console.log("schedule",this.schedule)
  
   setInterval(this.getTime, 500);
  },
  computed:{
    info(){
      return this.$store.getters.getInfo
    },
    infoName(){
      if(this.info){
        return this.info.Title
      }else{
        return "LX LIGHT CONTROLLER"
      }
    },
    schedule(){
      let schedule=this.$store.getters.getSchedule
      if(schedule){
        if(!schedule.lightMode){
          schedule.lightMode=0
        }
        if(!schedule.LightsON){
          schedule.LightsON="18:00"
          schedule.LightsON_OSC="/"
          schedule.LightsON_OSC_Param="1"
        }
        if(!schedule.LightsOFF){
          schedule.LightsOFF="23:59"
          schedule.LightsOFF_OSC="/"
          schedule.LightsOFF_OSC_Param="1"
        }
      }
      return schedule
    },
    deviceSettings(){
      let settings=this.$store.getters.getDeviceSettings
      console.log("DEVICE SETTINGS",settings)
      return settings
    },
    scheduleStatus(){
      return this.scheduleBtns.selected
    },
    debugMode(){
      return this.$store.getters.getDebugMode
    },
    buttons(){
      return this.$store.getters.getButtons
    },
    panels(){
      return this.$store.getters.getPanels
    },
    palettes(){
      return this.$store.getters.getPalettes
    },
    commandState(){
      console.log("COMMAND STATE",this.$store.getters.getCommand)
      return this.$store.getters.getCommand
    },
    lightState(){
      if(this.schedule){
        if(this.schedule.lightMode==0){
          return "off"
        }else if(this.schedule.lightMode==2){
          return "on"
        }
        let start=this.schedule.LightsON.split(":")
        let end=this.schedule.LightsOFF.split(":")
        let startTime=parseInt(start[0],10)*60+parseInt(start[1],10)
        let endTime=parseInt(end[0],10)*60+parseInt(end[1],10)
        let now=this.now.split(":")
        let nowTime=parseInt(now[0],10)*60+parseInt(now[1],10)
        if(endTime>startTime ){
          if(nowTime>startTime && nowTime<endTime){
            return "on"
          }else{
            return "off"
          }
        }else{
          if(nowTime>startTime && nowTime<endTime+24*60){
            return "on" 
          }else{
            return "off"
          }
        }
        
      }
      return "inactive"
    }
    
  },
  methods:{
    setUpPanels(){
     if(this.deviceSettings && this.deviceSettings.length>1){
      let panel
      console.log("We Have Settings",this.deviceSettings)
      for(var p in this.deviceSettings){
        panel=this.deviceSettings[p]
        this.$refs[panel.Name][0].setValue(panel)
      }
       }else{
          console.log("No Settings")
          console.log("BUT HAVE",this.panels)
          let dSettings=[]
          let panel
          for(var p in this.panels){
            panel=this.panels[p]
            dSettings.push({Name:panel.Name,Value:""})
          }
          this.$store.commit('setDeviceSettings',dSettings)
          this.$store.dispatch('initDeviceSettings',dSettings)
     }
    },
    getStartTime(){
      let startTime = prompt("Enter Start Time", this.schedule.LightsON);
        let text;
        if (startTime == null || startTime == "") {
          text = "No Answer";
        } else {
          text = "new start time" + startTime ;
          this.schedule.LightsON=startTime
          this.$store.dispatch("updateSchedule",(this.schedule))
         
        }
        
        console.log(text)
    },
    getEndTime(){
      let endTime = prompt("Enter End Time", this.schedule.LightsOFF);
        let text;
        if (endTime == null || endTime == "") {
          text = "No Answer";
        } else {
          text = "new end time" + endTime ;
          this.schedule.LightsOFF=endTime
        this.$store.dispatch("updateSchedule",(this.schedule))
          
        }
        
        console.log(text)
    },
    setPageWidth(){
      this.pageWidth=this.$refs.panel.offsetWidth
      //this.pageWidth=this.$refs.selectorX.offsetWidth
    },
    doButton(cmd){
      console.log("CLICK",cmd)
      this.$store.dispatch("doButton",cmd)
    },
    
    getTime(){
      var today = new Date();
      var time = today.getHours() + ":" + this.twoDigit(today.getMinutes()) + ":" + this.twoDigit(today.getSeconds());
      this.now=time
    },
    twoDigit(val){
      if(val<10){
        return "0"+val
      }else{
        return val
      }
    }
    
   
  },
  watch:{
    schedule(val){
      console.log("SCHEDULE UPDATE",val)
      this.scheduleBtns.selected=val.lightMode
      this.setUpPanels()
      
    },
    scheduleStatus(val){
      console.log("scheduleStatus",val,this.scheduleBtns.buttons[val],this.schedule)
      this.schedule.lightMode=val
      if(val==2){
        this.$store.dispatch("doButton",{name:"LightsON",osc:this.schedule.LightsON_OSC,param:this.schedule.LightsON_OSC_Param})
      }else if(val==0){
        this.$store.dispatch("doButton",{name:"LightsOFF",osc:this.schedule.LightsOFF_OSC,param:this.schedule.LightsOFF_OSC_Param})
      }
      this.$store.dispatch("updateSchedule",this.schedule)
    }
  }
  
}
