<template>
  <div id="app">

    <router-view/>
  </div>
</template>
<script>
// @ is an alias to /src


export default {
  name: 'APP',
  
  data() {
      return {
        
      }
  },
  mounted(){
    
    
   
    setTimeout(this.startListeners,500)
    
  },
  computed:{
    
  },
  methods:{
    startListeners(){
      this.$store.dispatch("initiateListeners")
    }
  }
}
</script>
<style lang="scss">
@import "@/css/colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
body{
  margin:0px;
  padding:0px;
  width:100%;
  font-size:16px;
  font-family: "Inter", sans-serif;
	background-color: rgb(0 25 60);
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
  float:right;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.webBtn{
    cursor:pointer;
    border:solid #000 1px;
    line-height:18px;
    border-radius:3px;
    padding:5px;
    margin:0px 5px;
    height:15px;

}
  .flexPad{
    flex-grow:1;
  }
  .panel{
    background-color:#000;
    border-radius:49px;
    color:#fff;
    font-family: "Open Sans";
    font-weight:400;
    text-align: left;
    transform:scale(1);
    font-size:1em;
    width:100%;
  }
  .panelTop{
     padding:20px;
     padding-bottom: 10px;
  }
  .panelBase{
    display:flex;
     padding:52px;
  }
  .title{
    font-family: "Oswald";
    text-transform: uppercase;
    font-size:2.2em;
    color:#fff;
   
  }
  .title2{
    font-family: "Oswald";
    text-transform: uppercase;
    font-size:2em;
    color:#fff;
   
  }
  .subTitle{
    font-size:1.5em;
    margin-bottom:35px;
    text-transform:none;
  }
  .searchRow{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin:6px 0px;
    text-transform: uppercase;
    font-weight:700;
  }
  .searchBox{
    background-color: #fff;
    color:#000;
    height:3em;
    border-radius:2em;
    font-family: "Open Sans";
    font-size:1em;
    text-transform: none;
    margin-top:6px;
    line-height: 54px;
    padding:0px 10px 0px 10px;
  }
  .results{
    background-color: #000;
    color:#fff;
  }
  .diffRow{
    display:flex;
    
    background-color: $base-grey;
    border-radius:27px;
    margin-top:6px;

  }
  .diffBtn{
   
    color:#fff;
   text-transform: none;
    width:180px;
    height:54px;
    border-radius:27px;
    font-size:1.2px;
    text-align: center;
   font-weight:400;
   
    line-height: 54px;
    
  }
  .pillBtn{
   background-color: #fff;
    border-radius: 2em;
    font-size: 1em;
    color: #000;
    font-weight: 700;
    padding: 1em 2em;
    text-align: center;
    text-decoration: none;
    border:1px solid #fff;
    margin: auto;
    cursor:pointer;
  }
  .pillBtn:hover{
    background-color: #000;
    color: #fff;
  }
  .treeBG{
  background-color:#333;
  
  min-height:100vh;
}

.Global{
  background-color: $global-color;
}
@media only screen and (max-width: 620px) {
    .panel{
      font-size:15px;
    }
    .panelTop{
      padding:2.5em 2em;
    }
    .treeBG{
      padding:7em 0em;
    }
    .title{
      font-size:2.1em;
    }
  
  @media only screen and (max-width: 520px) {
  .panel{
      font-size:14px;
    }
    .title{
      font-size:2.31em;
    }
  }
}
</style>
