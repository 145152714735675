import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getDatabase, ref, set ,onValue} from "firebase/database";
//import { getDatabase } from "firebase/database";

Vue.use(Vuex)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    debugMode:false,
    buttons:[],
    panels:[],
    palettes:[],
    command:null,
    locations:[],
    names:[],
    playMode:"disconnected",
    connectionWEB:false,
    connectionOSC:false,
    gameState:"",
    buttonState:"",
    timer:null,
    schedule:null,
    info:null,
    deviceSettings:null,
    device:"Device1"
   
  },
  mutations: {
    setDevice(state,payload){
      state.device=payload
    },
    setDeviceSettings(state,payload){
      console.log("setDeviceSettings Payload",payload)
      state.deviceSettings=payload
    },
    updateDeviceSettings(state,payload){
      let ds
      for(var s in state.deviceSettings){
        ds=state.deviceSettings[s]
        if(ds.name==payload.panel){
          ds.value=payload.value
        }
      }
    },
    setInfo(state,payload){
      state.info=payload
    },
    setButtons(state,payload){
      state.buttons=payload
    },
    setPanels(state,payload){
      state.panels=payload
    },
    setPalettes(state,payload){
      state.palettes=payload
    },
    setSchedule(state,payload){
      state.schedule=payload
    },
    
    setTimer(state,payload){
      state.timer=payload
    },
    setCommand(state,payload){
      state.command=payload
    },
    setConnectionWEB(state,payload){
      state.connectionWEB=payload
    },
    setConnectionOSC(state,payload){
      state.connectionOSC=payload
    },
    
    setDebugMode(state,payload){
      state.debugMode=payload
    }
  },
  actions: {
    setDevice({commit},payload){
      commit("setDevice",payload)
    },
    doButton({commit,getters,dispatch},payload){
      const db = getDatabase();
      console.log("DO BUTTON",payload)
      commit("setCommand",payload.name)
      let ds
      let deviceSettings = getters.getDeviceSettings
      for(var s in deviceSettings){
        ds=deviceSettings[s]
        if(ds.Name==payload.panel){
          ds.Value=payload.value || null
          ds.osc=payload.osc
          ds.type=payload.type
          ds.param=payload.param
          if(payload.oscList){
            ds.oscList=payload.oscList
          }
        }
      }
      dispatch("updateDeviceSettings",deviceSettings)
     
      set(ref(db, "Devices/"+getters.getDevice+'/Command'), {name:payload.name,type:payload.type||null,osc:payload.osc,param:payload.param});
    },
    
    updateSchedule({commit,getters},payload){
        console.log("UPDATE DEVICE",payload)
        const db = getDatabase();
        let cRef= firebase.firestore().collection("Devices")
        let schedule={Schedule:payload}
        cRef.doc(getters.getDevice).update(schedule);
        set(ref(db, "Devices/"+getters.getDevice+'/Schedule/updated'), false); 
    },
    updateDeviceSettings({commit,getters},payload){
     // console.log("UPDATE DEVICE Settings",payload)
      const db = getDatabase();
      let cRef= firebase.firestore().collection("Devices")
      let settings={Settings:payload}
      cRef.doc(getters.getDevice).update(settings);
      set(ref(db, "Devices/"+getters.getDevice+'/Settings/updated'), false); 
  },
    initDeviceSettings({commit,getters},payload){
     // console.log("INIT DEVICE Settings",payload)
      const db = getDatabase();
      let cRef= firebase.firestore().collection("Devices")
      let settings={Settings:payload}
      cRef.doc(getters.getDevice).update(settings);
      set(ref(db, "Devices/"+getters.getDevice+'/Settings/updated'), false); 
  },
   
    initiateListeners({ commit,getters}){
      const db = getDatabase();
      const   command = ref(db, "Devices/"+getters.getDevice+'/Command');

        onValue(command, (snapshot) => {
          const data = snapshot.val(); 
          console.log("Command",data)
          if(data.name && data.name!=""){
            console.log("SET Command ",data.name)
            commit("setCommand",data.name)
          }else{
            commit("setCommand",null)
            console.log("Command Empty")
          }  
        });
        const OSC_Status = ref(db, 'TreeState/OSC_Status');
        onValue(OSC_Status, (snapshot) => {
          const data = snapshot.val(); 
          console.log("OSC_Status",data)
          commit('setConnectionOSC',data)
          commit('setConnectionWEB',true)
        });
    },
   
    
    getSettings({commit,getters},payload){ 
      firebase.firestore().collection("Settings").get().then((query) => {  
        var buttons=[]
        var panels=[]
        var palettes=[]
        for (var doc in query.docs){
          var setting=query.docs[doc].data() 
          var name=query.docs[doc].data().name
            switch(name){
              
              case "Interface":
                console.log("Palettes",setting.Palettes)
                  for (var p in setting.Palettes){
                    palettes.push(setting.Palettes[p])
                  }
                  commit('setPalettes',palettes)
                  console.log("Buttons",setting.Buttons)
                  for (var b in setting.Buttons){
                    console.log(setting.Buttons[b])
                    buttons.push(setting.Buttons[b])
                  }
                  commit('setButtons',buttons)
                  console.log("Panels",setting.Panels)
                  for (var p in setting.Panels){
                    panels.push(setting.Panels[p])
                  }
                  panels.sort(function(a, b) {
                    return a.panelOrder - b.panelOrder
                });
                  commit('setPanels',panels)
                  
                  break;
              
            }
          
        }
    }).catch(err => {
        console.log('Error logged', err);
      })
      
      let device=getters.getDevice
        
        firebase.firestore().collection("Devices").doc(device).get().then((query) => {  
         
          doDevice(query.data()) 
       }).catch(err => {
         console.log('Error logged', err);
       })
    
      
      function doDevice(queryDocs){
        console.log("DO DEVICE",queryDocs)
        var setting
        var oscSettings
        
          console.log("Set Device ",queryDocs)
          commit('setSchedule',queryDocs.Schedule)
          commit("setInfo",queryDocs.Info)
          commit("setDeviceSettings",queryDocs.Settings)
          
       
        
      }

    },
    

    
  },
  getters:{
    getDevice(state){
      return state.device
    },
    getDeviceSettings(state){
      return state.deviceSettings
    },
    getInfo(state){
      return state.info
    },
    getPalettes(state){
      return state.palettes
    },
    getButtons(state){
      return state.buttons
    },
    getPanels(state){
      return state.panels
    },
    getSchedule(state){
      return state.schedule
    },
    getTimer(state){
      return state.timer
    },
    getCommand(state){
      return state.command
    },
    getConnectionWEB(state){
      return state.connectionWEB
    },
    getConnectionOSC(state){
      return state.connectionOSC
    },
    getGameState(state,){
     return  state.gameState
    },
    getDebugMode(state){
      return state.debugMode
    },
    getPaletteName: (state) => (id) => {
      if(state.palettes){
        for(var p in state.palettes){
          if(state.palettes[p].name==id){
            return state.palettes[p]
          }
        }
      }
        return "XXX"
      
    }
   
    
    
    
  },
  modules: {
  }
})
