<template>
  <div>
    <div  v-if="toggleGroup">
      {{ toggleGroup.Name }}
    
      <Button  v-for="(tb,rbi) in toggleGroup.buttons" :button="tb" :key="'tgb'+rbi" :ref="'TB'+tb.name" @click="doClick(toggleGroup,rbi)" style="display:flex; cursor:pointer;">
       </Button>  
      </div>
    </div>
 
</template>

<script>
import Button from '@/components/Button'
import { urlToHttpOptions } from 'url'
export default {
 
  props: {
   toggleGroup:{default:0},
  
  }, 
  mixins:[],
  components:{Button},
  data() {
    const self=this
    return {
     buttonState:false,
     buttonColor:"inactive",
     
   
    }
  },
  mounted(){
   
   
  
  },
  computed:{
    
  },
  methods:{
    setValue(val){
      let valName
      let button
      let refName
      let vals=val.Value.concat()
      for( var v in vals){
        valName = vals[v]
        for(var b in this.toggleGroup.buttons){
          button=this.toggleGroup.buttons[b]
          if(button.name==valName){
            refName="TB"+button.name.split("(")[0]
            this.$refs[refName][0].setOn(button)
          }
        }
      }

    },
   doClick(toggleGroup,index){
    toggleGroup.selected=index
   },
   doButton(button){
    let comm
    let selected
    let vals=[]
    let oscList=[]
    for( var b in this.toggleGroup.buttons){
      selected=this.toggleGroup.buttons[b]
      if(selected.param){
        vals.push(selected.name)
        oscList.push(selected.osc)
      }
    }
    comm={type:button.type,panel:this.toggleGroup.Name,name:button.name,osc:button.osc,param: button.param,value:vals,oscList:oscList}
    this.$store.dispatch("doButton",comm)
  }
   
    
  },
  watch:{
    
  }
}
</script>

