<template>
  <div class="treeBG" >
    <div class="panel" ref="panel" style="transform:scale(1)">
      <div class="panelTop">
        <div class="title">{{infoName}}</div>
        
        <div>
          
          <div style="display:flex;">
            <div style="display:flex;" v-if="schedule">
              <div style="display:flex;">
                Light State
                <div  :class="'box '+lightState"></div>
              </div>
             
            </div>
            
            <div style="margin-left:10px;display:flex;flex-grow:1;">  Current Time: {{ now}}  </div>
            <div style="display:flex;">Data
              <div v-if="commandState" class="box orange"></div>
              <div v-else class="box green"></div>
            </div>
          </div>
         
          <div >
          
          <div class="subpanel" style="display:flex;">
            <div v-if="schedule">
              <RadioButtons  :radioGroup="scheduleBtns"></RadioButtons>
            </div>
          <div>
            
          <div v-if="schedule" style="display:flex;margin-top:46px;margin-left:20px;">
          <div @click="getStartTime">On:{{ schedule.LightsON }} - </div>
          <div @click="getEndTime" >- Off:{{ schedule.LightsOFF }} </div> 
          </div>
        </div>
        </div>
          </div>
          <Panel v-for="(p,pi) in panels" :panel="p" :key="'p'+pi" :ref="p.Name"></Panel>
        </div>
       

        <div v-if="false" id="Buttons">
          <Button v-for="(b,bi) in buttons" :key="'b'+bi" class="oscBtn"  :button="b">{{ b.name }}</Button>
        </div>
        
        <div v-if="pageWidth <= 900">
          <div v-if="debugMode">Page less than 900</div>  
      </div>
      <div v-else>
        <div v-if="debugMode">page greater than 900</div>
      </div>
    </div> 
    
 <div class="panelBase">
  
 </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Device from '@/mixins/Device.js'
export default {
  name: 'Device2',
  components: {
   
  },
  mixins:[Device],
  data() {
      return {
      
      }
  },
  mounted(){
  
  
  },
  computed:{
   
  },
  methods:{
    setDevice(){
      this.$store.dispatch("setDevice","Device2")
    }
   
  },
  watch:{
   
  }
  
}


</script>
<style lang="scss" >
@import "@/css/colors.scss";
@import "@/css/default.scss"; 
 


</style>
