<template>
  <div class="subpanel">
   
    <div v-if="panel.Type=='radioButtons'">
      <RadioButtons  :radioGroup="panel" :ref="'P'+panel.Name"></RadioButtons>
    </div>
    <div v-else-if="panel.Type=='ColourPicker'">
      <Palette :palette="panel" :ref="'P'+panel.Name"></Palette>
    </div>
    <div v-else-if="panel.Type=='toggleButtons'">
      <ToggleButtons :toggleGroup="panel" :ref="'P'+panel.Name"></ToggleButtons>
    </div>
    <div v-else>
      <div >{{panel.Name}}</div>
   
    {{ panel }}
   PANEL END
  </div>
  </div>
</template>

<script>

import Button from '@/components/Button'
import RadioButtons from '@/components/RadioButtons.vue'
import ToggleButtons from '@/components/ToggleButtons.vue'
import Palette from '@/components/Palette.vue'

export default {
 
  props: {
   panel:{defult:null}
  }, 
  mixins:[],
  components:{Button,RadioButtons,ToggleButtons,Palette},
  data() {
    const self=this
    return {
     
    }
  },
  mounted(){
   
   
  
  },
  computed:{
    
  },
  methods:{
    setValue(val){
      this.$refs["P"+this.panel.Name].setValue(val)
    }
 
   
    
  },
  watch:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
