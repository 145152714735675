
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app'
import 'firebase/firestore';
import "firebase/analytics";


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  created(){
    const firebaseConfig = {
      apiKey: "AIzaSyDh089qZK6sYLnEK-3AYnvy5K0i56eUVTg",
      authDomain: "lxstudio-smithton-tree.firebaseapp.com",
      databaseURL: "https://lxstudio-smithton-tree-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "lxstudio-smithton-tree",
      storageBucket: "lxstudio-smithton-tree.appspot.com",
      messagingSenderId: "269068299269",
      appId: "1:269068299269:web:d3bc0c440e5f3146da006c",
      measurementId: "G-R1B6W06C5V"
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }

    //firebase.analytics();
    
  }
}).$mount('#app')

