<template>
  <div>
    <div style="display:flex;" v-on="handlers" @click="doClick(button)">
      <div :class="'box '+buttonColor" ></div>
    <div >{{button.name}}</div>
    
  </div>
  </div>
</template>

<script>



export default {
 
  props: {
   button:{defult:null}
  }, 
  mixins:[],
  components:{},
  data() {
    const self=this
    return {
     buttonState:false,
     buttonColor:"inactive",
     handlers: {
      mousedown: self.mousedown,
      touchstart: self.mousedown,
      mouseup: self.mouseup,
      touchend: self.mouseup
    }
    }
  },
  mounted(){
   
   
  
  },
  computed:{
    
  },
  methods:{
    setOn(button){
    if(this.button.type=="toggle"){
      this.buttonState=true
      if(this.buttonState){
        this.buttonColor="green"
      }else{
        this.buttonColor="inactive"
      }
      button.param=+this.buttonState 
    }else{
      button.param=1
    }
    this.$parent.doButton(button)
   },
   doClick(button){
    if(this.button.type=="toggle"){
      this.buttonState=!this.buttonState
      if(this.buttonState){
        this.buttonColor="green"
      }else{
        this.buttonColor="inactive"
      }
      button.param=+this.buttonState 
    }else{
      button.param=1
    }
    this.$parent.doButton(button)
   },
   mousedown(){
    if(this.button.type=="toggle"){
      this.buttonState=!this.buttonState
    }else{
      this.buttonColor="green"
    }
   },
   mouseup(){
    if(this.button.type=="toggle"){
      this.buttonState=!this.buttonState
    }else{
      this.buttonColor="inactive"
    }
   }
    
  },
  watch:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
